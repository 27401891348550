import {getCountryByCode} from "./countries";

export default {
  cleanMobile(mobileCountry, mobileNumber) {
    if (mobileCountry && mobileNumber[0] !== "+") {
      const {dialCode} = getCountryByCode(mobileCountry);
      let mobileno;
      if (mobileNumber[0] === "0") {
        mobileno = mobileNumber.substring(1);
      } else {
        mobileno = mobileNumber;
      }
      return `+${dialCode}${mobileno}`;
    }
    return mobileNumber;
  },
  getRandomInt(min, max) {
    if (min === max) {
      return min;
    }
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  },
  characterHide(text) {
    if (!text) {
      return "";
    }

    if (text?.trim() === "") {
      return text;
    }

    const textsSplitted = text?.split(/[@]/gim);
    let processedText = "";

    textsSplitted.map(t => {
      const replacerPercentage = 0.5; // in decimal form
      const replacerCount = Math.floor(t.length * replacerPercentage) > 1 ? Math.floor(t.length * replacerPercentage) : 0;
      processedText += "*".repeat(replacerCount) + t.slice(replacerCount);
    });

    return processedText;
  },
  toGlobalId(type, id) {
    return window.btoa(decodeURIComponent(encodeURIComponent([type, id].join(":"))));
  },
};

import React from "react";
import {Container} from "react-bootstrap";
import bgData from "../../images/bg/background_city.svg";

export default function Banner(props) {
  return (
    <div className="banner align-items-center d-flex">
      <img src={bgData} alt="background-city" className="banner-img-whole" />
      <Container className="banner-content">
        {props.children}
      </Container>
    </div>
  );
}

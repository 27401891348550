import React, {  } from "react";
import Main from "../components/main";
import Layout from "../components/section/layout";
import Seo from "../components/section/seo";
import UserCheck from "../components/auth";


export default function Index(props) {
  return (<Main>
    <Layout>
      <Seo title="GetGud - Portal Login" />
      <UserCheck {...props} />
    </Layout>
  </Main>)
}






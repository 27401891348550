import React, {useContext, useEffect, useState} from "react";
import {mfaContext} from "..";
import {Button} from "react-bootstrap";
import OtpInput from "../../../otp-input";

const MFAAdminGeneratedPanel = () => {
  const {loading, setMfaMethod, setShowMfa, activeVerification, handleApprove} = useContext(mfaContext);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [otpError, setOtpError] = useState(null);
  const [otpLoading, setOtpLoading] = useState(false);

  
  if (!loading && !activeVerification && activeVerification === null) {
    return (
      <div className="mfa-panel admin-generated-container">
        <p>{"Oh no! It seems like there are no available one-time password at the moment."}</p>
        <Button
          variant="login"
          onClick={() => {
            setShowMfa(false);
            setMfaMethod(null);
          }}
          disabled={loading}
        >
          {"Back to Login"}
        </Button>
      </div>
    );
  }
  useEffect(()=>{
    if (activeVerification && otp.join("").length === 6) {
      handleOtpSubmit(otp.join(""));
    }
  }, [otp]);

  const handleOtpSubmit = async (otp) => {
    setOtpLoading(true);
    const apiPath = config.backend;
    const data = await fetch(`${apiPath}rest.api/verify/${activeVerification.id}:${otp}`, {
      method: "GET",
    });

    const parsedData = await data.json();

    if (parsedData.success) {
      handleApprove(activeVerification.id);
    } else {
      setOtpError("Invalid code");
    }

    setOtpLoading(false);
  };

  return (
    <div className="mfa-panel">
      <p className="mfa-heading pb-3">{"Enter your code"}</p>
      <p className="mfa-sub-heading text-justify">{loading ? <i className="fad fa-spinner"/> :
        "You have received a one-time-passcode from the admin."}
      </p>
      <p className="mfa-sub-heading text-justify">{loading ? <i className="fad fa-spinner"/> :
        "Enter the code below to continue."}
      </p>
      <OtpInput
        count={6}
        value={otp}
        onChange={value => {
          setOtp(value);
          setOtpError(null);
        }}
      />
      {otpError && <div className="mfa-otp-input-error">{otpError}</div>}
      <div className="mfa-other-options-container">
        <span onClick={() => setMfaMethod(null)}>{"Use another method to receive the code"}</span>
        <span
          onClick={() => {
            setShowMfa(false);
            setMfaMethod(null);
          }}
        >{"Sign in to a different account"}</span>
      </div>
    </div>
  );
};


export default MFAAdminGeneratedPanel;